@import "src/assets/styles/colors_fonts";

.price__wrapper {
  width: 100%;
  height: 100%; }

.price__container {
  @include flex-center-sb;
  color: $primary_light;
  max-width: 1400px;
  width: 100%;
  margin: 50px auto;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: column;



 }  //background-color: #61dafb

.price__table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 100% !important;
  max-width: 100% !important;
  height: 100%;
 }  //background-color: #fad430

.price__table-block {
  display: flex;
  flex-direction: column; }

.price__table-line {
  color: black;
  display: flex;
  justify-content: space-between;
  min-width: 100% !important;
  border-bottom: 1px solid gray;
 }  //background-color: red


.price__table-cell-first {
  width: 32%;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0; }


.price__table-cell {
  width: 22.6%;
  padding: 15px 0;
  word-break: break-all;
  @include flex-all-center;
  button {
    margin-top: 10px;
    min-width: 87px !important; } }

.price__firstCell-flex {
  display: flex;
  flex-direction: column;
  h4 {
    color: $primary_blue_dark_text;
    padding: 0;
    margin: 10px 0 5px 0; }
  p {
    color: black;
    margin: 0 0 10px 0; } }

.price__cell-center {
  display: flex;
  flex-direction: column;
  @include flex-all-center;
  text-align: center; }

.price__table-active {
  display: block; }

.price__table-hide {
  display: none; }

.price__title_h4 {
  margin: 5px 0 0 15px;
  padding: 0;
  color: black;
  font-size: 20px;
  cursor: pointer;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none; }

.price__openingTab {
  display: flex;
  padding-top: 20px;
  cursor: pointer;
  img {
    width: 35px;
    height: 35px;
    cursor: pointer; } }

@media screen and (max-width: 500px) {
  .price__table-cell {
    width: 22.6%;
    padding: 15px 0;
    word-break: break-all;
    align-items: flex-start !important; } }
