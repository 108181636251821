@import "src/assets/styles/colors_fonts";

.homePage__top-container {
  @include flex-center-sb;
  position: relative;
  width: 100%;

  height: 500px; }

.homePage__backline-container {
  @extend .homePage__top-container;
  margin-top: 100px;
  min-height: 750px;
  height: 100%; }

.homePage__top-block {
  @include flex-center-sa;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  z-index: 10; }

.homePage__code-block {
  @extend .homePage__top-block;
  margin-top: 100px; }


.homePage__backline-block {
  @extend .homePage__top-block; }

.homePage__icons-block {
  @include flex-center-sa;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  z-index: 10;
  flex-direction: column; }

.homePage__top-left {
  @include flex-columns;
  align-items: flex-start;
  color: $primary_light;
  padding-left: 60px;
  width: 63%;
  h1 {
    max-width: 590px;
    font-size: 51px !important; }
  h3 {
    max-width: 510px;
    font-size: 28px !important; } }

.homePage__top-img {
  width: 80%;
  height: 45%;
  margin-left: 30px; }

.homePage__top-right {
  display: flex;
  justify-content: flex-end;
  color: white;
  p {
    font-size: 18px;
    max-width: 400px; } }

.homePage__codeTabs-container {
  margin-top: 70px;
  color: black;
  display: flex;
  justify-content: space-between;
  width: 40%;
  div {
    cursor: pointer; } }


//code preview [Highlight] change styles
.homePage__top-left {
  .pgsql {
    padding: 60px !important;
    width: 600px;
    height: 200px;
    overflow-y: auto;
    word-break: break-word; } }

.homePage__right-description {
  padding-left: 50px; }

.homePage__middle-right {
  display: flex;
  justify-content: flex-start; }

.homePage__noActiveTab {
  background-color: $primary_blue;
  color: white;
  height: 35px;
  padding: 15px;
  @include flex-all-center;
  &:hover {
    text-decoration: underline white; } }

.homePage__activeTab {
  background-color: $primary_blue_dark;
  color: white;
  @extend .homePage__noActiveTab;
  &:hover {
    text-decoration: none; } }

.homePage__bottom-block {
  @extend .homePage__top-block;
  flex-direction: column;
  margin: 70px auto 140px auto; }

.backs {
  width: 100%;
  height: 100%;
  //transform: rotate(-4deg)
  transform: skewY(-3deg);
  position: absolute;
  top: 0;

  background-color: $primary_blue;
  z-index: 3;
  opacity: .6;
  -webkit-box-shadow: 0px 10px 23px -11px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 23px -11px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 23px -11px rgba(0,0,0,0.75); }

.backs2 {
  width: 100%;
  height: 100%;
  //transform: rotate(-4deg)
  transform: skewY(-3deg);
  position: absolute;
  top: 0;
  background: url("../../assets/images/back3.jpg"), 100% 100%;
  z-index: 1;
  -webkit-box-shadow: 0px 10px 23px -11px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 23px -11px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 23px -11px rgba(0,0,0,0.75); }

//.background-line
//  background: url("../../assets/images/back3.jpg"), 100% 100%
//  background-color: #0162d0 !important


.backs-revert {
  @extend .backs;
  transform: skewY(3deg); }

.backs2-revert {
  @extend .backs2;
  transform: skewY(3deg); }

.homePage__iconsLine {
  margin-top: 80px;
  display: flex;
  width: 100%;
  justify-content: center; }

.homePage__iconsCell {
  display: flex;
  flex-direction: column;
  width: 30%;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
    height: 100px; }
  h5 {
    margin: 10px 0 0 0;
    padding: 0;
    text-align: center;
    font-size: 20px; }
  h4 {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 24px; }
  p {
    margin: 10px 0 0 0;
    width: 50%;
    text-align: center;
    padding: 0; } }

.h4-no-paddings {
  padding: 0 !important;
  margin: 0 !important; }

.code-class {
  overflow-x: auto;
  padding: 0.5em;
  margin-top: 140px;
  width: 554px !important;
  display: flex;
 }  //justify-content: flex-end
