@import "src/assets/styles/colors_fonts";


.partner_line {
  display: flex;
  //width:
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-around;
  //background-color: #fad430
  //padding: 3% 10% 3% 20%
  margin-bottom: 100px; }
.partners_round {
  @include block-shadow;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  @include flex-all-center;
  background-color: $primary_blue;
  color: white;
  margin: 0 30px;
  flex-direction: column;
  text-align: center;
  img {
    width: 50px !important;
    height: 50px;
    margin-top: 0px !important;
    margin-bottom: 20px; } }


.partner__container {
  color: black;
  max-width: 1400px;
  width: 100%;
  margin: 50px auto;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-top: 20px;
    width: 100%; } }

.partner_about-line {
  display: flex;
  margin: 10px 0 60px 0; }

.partner_about-left {
  display: flex;
  flex-direction: column;
  width: 50%; }

.partner_about-right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0162d0;
  border-radius: 40px;
  transform: skewY(-3deg);
  margin-left: 70px;
  color: white;
  font-size: 16px;
  padding: 20px;
  @include block-shadow; }


.partner_about-left-variant {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0162d0;
  border-radius: 40px;
  transform: skewY(3deg);
  margin-right: 70px;
  color: white;
  font-size: 16px;
  padding: 20px;
  @include block-shadow; }

.partner_about-right-variant {
  display: flex;
  flex-direction: column;
  width: 50%; }

.partner-quote {
  width: 30px !important;
  height: 30px;
  margin-right: 5px; }
