@import "src/assets/styles/colors_fonts";


.header__container {
  //background-color: $primary_blue
  width: 100%;
  height: 100px;
  color: $primary_blue; }

//Left side styles
.header__block {
  display: flex;
  align-items: center;
  color: $primary_blue;
  max-width: 1400px;
  margin: 0 auto;
  height: 100%;

  h1 {
    @include primary_font-h1; }

  .header__block-img {
    //max-width: 55px
    max-height: 55px;
    margin-right: 100px; } }

.header__block-left {
  @include flex-center-sb;
  max-width: 300px; }

//Navbar styles
.header__menu-container {
  @include flex-center-sb; }

.header__menu-title {
  @include primary_font-menu;
  @include flex-all-center;
  margin: 0 20px 0 0;
  cursor: pointer;
  padding: 10px 0;
  &:hover {
    text-decoration: underline white; }

  &:last-child {
    margin-left: 35px; }

  img {
    width: 10px;
    height: 10px;
    margin-left: 6px; } }


.header__block-right {
  padding-top: 10px; }

//Product`s block (from menu) styles
.products-dropdown {
  position: relative; }


.products-title {
  text-decoration: none !important;
  color: $primary_blue;
  transition-duration: .4s;
  &:hover {
    color: $primary_blue_dark;
    transition-duration: .4s; }
  a {
    font-family: Ubuntu, sans-serif;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px 0 0;
    cursor: pointer;
    padding: 10px 0;
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline white; } }
  &:hover > .products__container {
    display: flex; } }



.products__container {
  @include block-shadow;
  @include flex-columns;
  color: $primary_dark;
  width: 80%;
  display: none;
  left: -50px;
  top: 35px;
  border-radius: 10px;
  padding: 20px;
  background-color: $primary_light;
  position: absolute;
  z-index: 20;
  cursor: default; }

.products__block {
  width: 100%;
  margin: 0 0 15px 0;
  border-bottom: 1px solid gray;

  &:last-child {
    border-bottom: none; } }

.products__block-line {
  @include flex-center-sa;
  width: 100%;
  margin: 25px 0; }

.products__block-cell {
  min-width: 33.2%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer; }

.products__subtitle {
  a {
    font-weight: 400 !important; } }

.products__block-cell {
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    color: black; } }

.two-item-block-cell {
  @extend .products__block-cell;
  max-width: 23.2%; }

.two-item-block-cell {
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    color: black; } }

.flex-start {
  justify-content: flex-start; }

.menu-icon-img {
  width: 35px !important;
  height: 35px !important;
  margin-right: 5px; }

.header__block-left {
  a {
    text-decoration: none;
    display: flex;
    color: $primary_blue_dark; } }



.right-menu {
  cursor: pointer;
  display: none; }

#sandwich-1 {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 40px;
  right: 30px;
  margin: auto;
  cursor: pointer; }

#sandwich {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 40px;
  right: 30px;
  margin: auto;
  cursor: pointer; }

.sw-topper {
  position: relative;
  top: 0;
  width: 40px;
  height: 5px;
  background: #fff;
  border: none;
  border-radius: 4px 4px 4px 4px;
  transition: transform 0.5s, top 0.2s;
  cursor: pointer; }
/* transition-delay: 0.2s, 0s; */

.sw-bottom {
  position: relative;
  width: 40px;
  height: 5px;
  top: 5px;
  background: #fff;
  border: none;
  border-radius: 4px 4px 4px 4px;
  transition: transform 0.5s, top 0.2s;
  transition-delay: 0.2s, 0s;
  cursor: pointer; }

#sandwich .sw-topper {
  top: 5px;
  transform: rotate(140deg); }

#sandwich .sw-bottom {
  top: 0;
  transform: rotate(-140deg); }



@media screen and (max-width: 500px) {
  .right-menu {
    display: flex; }
  .mobile-menu {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
    .header__menu-container {
      width: 100%;
      flex-wrap: wrap;
      margin: 0 auto 30px 0 !important;
      justify-content: center;
      color: $primary_blue_dark;
 } } }      //background-color: $primary_blue


.products__container-mobile {
  @extend .products__container;
  display: flex !important;
  width: 88%;
  left: 0px;
  top: 70px; }

.link-title {
  text-decoration: none !important;
  color: $primary_blue;
  transition-duration: .4s;
  &:hover {
    color: $primary_blue_dark;
    transition-duration: .4s; }
  a {
    text-decoration: none !important;
    color: $primary_blue;
    transition-duration: .4s;
    &:hover {
      color: $primary_blue_dark;
      transition-duration: .4s; } } }
