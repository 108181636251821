@import "src/assets/styles/colors_fonts";

.devs__container {
  //@include flex-center-sb
  color: black;
  max-width: 1400px;
  width: 100%;
  margin: 50px auto;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center; }

.devs_line {
  display: flex;
  //width:
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-between;
  //background-color: #fad430
  padding: 3% 10% 3% 20%; }

.devs_block {
  display: flex;
  flex-direction: column;
  width: 32%;
  //background-color: #0162d0
  border: 2px solid white;
  margin: 10px 0;
  h4 {
    margin: 10px 0; }
  a {
    color: black;
    text-decoration: none;
    &:hover {
      color: $primary_blue_dark;
      text-decoration: underline $primary_blue_dark; } }


  p {
    padding: 3px 0;
    margin: 0;
    cursor: pointer;
    &:hover {
      color: $primary_blue_dark;
      text-decoration: underline $primary_blue_dark; } } }
