@import "src/assets/styles/colors_fonts";

.pageGen_wrapper {
  @include flex-center-sa;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  //height: calc(100vh - 200px)
  flex-direction: column;
  padding: 50px 0 200px 0; }

.page-li {
  padding: 10px; }

.pageGen__code {
  padding: 50px 0 0 0; }

.pageGen__code {
  .pgsql {
    padding: 60px !important;
    width: 600px;
    height: 200px;
    overflow-y: auto;
    word-break: break-word; } }
