@import "src/assets/styles/colors_fonts";

.footer__container {
  //background-color: rgba(92,149,215, 1)
  background-color: rgba(65, 131, 197, 1);
  width: 100%;
  height: 100px; }

.footer_block {
  padding: 20px 0 20px 0;
  color: white;
  @include flex-center-sb;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  div {
    cursor: pointer; }
  div:hover {
    text-decoration: underline white; } }

.footer_copy {
  color: white;
  @include flex-all-center; }

.footer_block {
  a {
    text-decoration: none;
    color: white; } }
